import React from 'react';
import styled, { StyledComponent } from 'styled-components';
import { Spin as SpinAntd } from 'antd';
import { getPrimary } from 'helper/theme';

export const Spin: any = styled(SpinAntd)`
  && {
    .ant-spin-dot-item {
      background-color: ${getPrimary};
    }
  }
`;
