import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import { getBase, getPrimary, getPrimaryHover, getPrimaryObj } from 'helper/theme';

export const MenuWrapper = styled<any>(Menu)`
  background-color: ${getPrimary};
  && {
    .ant-menu-item-selected {
      background-color: ${getPrimaryHover};

      &:hover {
        > svg {
          fill: ${getPrimary};
          stroke: ${getPrimary};
        }
        .ant-menu-title-content {
          color: ${getPrimary};
        }
      }
      &:after {
        border-color: ${getPrimary};
        border-right: 3px solid ${getPrimary};
      }
    }
    .ant-menu-submenu-selected .ant-menu-title-content,
    .ant-menu-submenu-selected .ant-menu-submenu-arrow {
      color: ${getPrimaryHover};
    }
    .ant-menu-submenu-selected .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      background-color: ${getBase};
    }

    .ant-menu-submenu-selected {
      > div > svg {
        fill: ${getPrimaryHover};
        stroke: ${getPrimaryHover};
      }

      &:hover {
        > div > svg {
          fill: ${getPrimaryHover};
          stroke: ${getPrimaryHover};
        }
        .ant-menu-title-content {
          color: ${getPrimaryHover};
        }
        .ant-menu-submenu-arrow {
          color: ${getPrimaryHover};
        }
      }
    }
  }
`;

export const MenuItem = styled<any>(Menu.Item)`
  && {
    background-color: ${getPrimary};
    .ant-menu-title-content {
      color: ${getPrimary};
    }
    &:hover {
      svg {
        fill: ${getPrimaryHover};
        stroke: ${getPrimaryHover};
      }
      .ant-menu-title-content a,
      .ant-menu-submenu-arrow {
        color: ${getPrimaryHover};
      }
    }
  }
`;

export const SubMenu = styled<any>(Menu.SubMenu)`
  && {
    background-color: ${getPrimary};

    &.ant-menu-submenu-selected {
      .ant-menu-item-selected {
        background-color: ${getPrimaryHover};
        .ant-menu-title-content {
          color: ${getPrimary};
        }
      }
    }

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
      color: #999999;
    }
    &:hover {
      color: ${getPrimaryHover};
      .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: ${getPrimaryHover};
      }
    }
    &:hover {
      > div > svg {
        fill: ${getPrimaryHover};
        stroke: ${getPrimaryHover};
      }
    }
  }
`;
