import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxAntd } from 'antd';
import { getPrimary } from 'helper/theme';

export const Checkbox = styled(CheckboxAntd)`
  && {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${getPrimary};
      border-color: ${getPrimary};
    }
  }
`;
