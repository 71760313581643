import React from 'react';
import { ReactComponent as MedalIcon } from 'assets/images/medal.svg';
import { USER_ROLE } from 'common/constant';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { SvgWrapper, Title } from 'components/Theme';
import classNames from 'classnames';

interface IProps {
  role: number;
  textNormal?: boolean;
}

interface IUserRole {
  numMedal: number;
  name: string;
}

const RoleDisplayWithIcon = (props: IProps) => {
  const { role, textNormal = false } = props;

  const { t } = useTranslation();

  const getUserRole = (): IUserRole => {
    let userRole = {
      numMedal: 1,
      name: t('common.roleStaff'),
    };

    switch (role) {
      case +USER_ROLE.USER_ROLE:
        break;
      case +USER_ROLE.MANAGER_ROLE:
        userRole.numMedal = 2;
        userRole.name = t('common.roleManager');
        break;
      case +USER_ROLE.ADMIN_CMS_COMPANY:
        userRole.numMedal = 3;
        userRole.name = t('common.roleAdmin');
        break;
      case +USER_ROLE.ADMIN_CMS_SYSTEM:
        userRole.numMedal = 4;
        userRole.name = t('common.roleSystem');
        break;
      default:
        break;
    }

    return userRole;
  };

  return (
    <div className={styles.roleContainer}>
      {Array.from(Array(getUserRole().numMedal).keys()).map((i) => (
        <SvgWrapper key={i} className={styles.iconContainer}>
          <MedalIcon />
        </SvgWrapper>
      ))}
      <Title className={classNames(styles.text, `${textNormal ? styles.textBlack : ''}`)}>{getUserRole().name}</Title>
    </div>
  );
};

export default RoleDisplayWithIcon;
