export default {
  DATE_PICKER_TITLE: 'YYYY年MM月',
  PAGE_SIZE: 10,
  MAX_PAGE_SIZE: 9999,
  ZIPCODE_URL: 'https://www.post.japanpost.jp/zipcode/',
  REGEX_ONLY_NUMBER_AND_DOT: /^[0-9]*\.?[0-9]*$/,
  REGEX_ONLY_NUMBER_AND_COMMA: /^[\,0-9]*$/,
  REGEX_KANA: /^([ァ-ンｧ-ﾝﾞﾟ]|ー|ヴ|ｳﾞ| )+$/,
  MAX_FILE_UPLOAD: 5,
  DEFAULT_COUNT_USER: 4,
  NO_REPEAT: 1,
  NO_NOTIFY: 1,
  DEFAULT_POPUP_WIDTH: 650,
};

export enum STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum HOST {
  IS_HOST = 1,
}

export enum REPEAT {
  NO_REPEAT = 1,
  DAY = 2,
  WEEK = 3,
  MONTH = 4,
}

export enum GENDER {
  MALE = 1,
  FEMALE = 0,
}

export enum PURCHASE_STATUS {
  PURCHASE = 1,
  SALE = 2,
}

export enum USER_ROLE {
  ADMIN_CMS_SYSTEM = '1',
  ADMIN_CMS_COMPANY = '2',
  MANAGER_ROLE = '3',
  USER_ROLE = '4',
}

export enum TWO_FACTOR_AUTHENTICATION {
  ACTIVE = 1,
  INACTIVE = 2,
}

export const roles = [
  USER_ROLE.ADMIN_CMS_SYSTEM,
  USER_ROLE.ADMIN_CMS_COMPANY,
  USER_ROLE.MANAGER_ROLE,
  USER_ROLE.USER_ROLE,
];

export enum IMPORT_TYPE {
  DIVISION = 1,
  USER = 2,
  CUSTOMER = 3,
  PROPERTY = 4,
  SAlES = 5,
}

export enum FILTER_TYPE_PROJECT_BY {
  STAFF = 1,
  ALL = 2,
  MANAGER = 3,
  ANOTHER_DIV = 4,
}

export enum CHOICES {
  UPDATE = 1,
  DELETE = 2,
  ACCEPT = 3,
  REJECT = 4,
}

export enum REAL_ESTATE_STATUS {
  WAITING = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export enum REAL_ESTATE_TYPE {
  LAND = 1,
  DETACH_HOUSE = 2,
  APARMTENT = 3,
}

export enum TYPE_SORT_CUSTOMER {
  RES_YEAR_ASC = 1,
  RES_YEAR_DESC = 2,
  AGE_ASC = 3,
  AGE_DESC = 4,
  REGIS_DATE_ASC = 5,
  REGIS_DATE_DESC = 6,
}

export enum STATUS_PROJECT {
  INPROGRESS = 0,
  REQUEST_CLOSE = 1,
  CLOSE_FAIL = 2,
  CLOSE_SUCCEES = 3,
  REJECT = 4,
  CANCEL = 5,
}

export enum MEETING_TYPE {
  INTERNAL = 1,
  CUSTOMER = 2,
}

export enum ACTION_CALENDAR {
  CALENDAR_ME = 1,
  CALENDAR_ALL = 2,
  CALENDAR_DIVISION = 3,
}

export enum IS_PUBLIC {
  PUBLIC = 1,
  PRIVATE = 0,
}

export enum ACCEPT {
  UNKNOWN = 0,
  IS_ACCEPT = 1,
  NOT_ACCEPT = 2,
}

export enum STATUS_PHASE {
  IN_PROGRESS = 0,
  REQUEST_CLOSE = 1,
  FAIL_CLOSE = 2,
  SUCCESS_CLOSE = 3,
}

export enum STEP_PHASE {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
  STEP5 = 5,
  STEP6 = 6,
  STEP7 = 7,
  STEP8 = 8,
  STEP9 = 9,
}

export enum PRICE {
  LEVEL1 = 2000000,
  LEVEL2 = 4000000,
}

export enum CHOICE {
  ONE = 1,
  ALL = 2,
}

export const FORMAT_DATE1 = 'YYYY/MM/DD';

export const FORMAT_DATE2 = 'YYYY-MM-DD';

export const FORMAT_DATE_TIME = 'YYYY-MM-DD hh:mm:ss';

export const FORMAT_DATE_TIME_24 = 'YYYY-MM-DD HH:mm:ss';

export const DATE_FORMAT = 'YYYY年MM月DD日';

export const YEAR_MONTH = 'YYYY年M月';

export const weekDays = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];

export const listDay = ['日', '月', '火', '水', '木', '金', '土'];

export const MASTER_FIELD_INTEGER = 'integer';
export const MASTER_FIELD_STRING = 'string';
export const MASTER_FIELD_TEXT = 'text';

export const masterFieldObj = {
  [MASTER_FIELD_STRING]: {
    placeholder: 'validate.customFieldString',
    maxLength: 100,
  },
  [MASTER_FIELD_TEXT]: {
    placeholder: 'validate.customFieldText',
    maxLength: 300,
  },
};

export enum PATTERN_TYPE {
  BDS = 1,
  Customer = 2,
}

// FIREBASE CONSTANT
export const FIREBASE_TOPIC_RANKING_USER = (companyId: number | string) => `ranking_user_${companyId}`;
export const FIREBASE_TOPIC_RANKING_DIV = (companyId: number | string) => `ranking_division_${companyId}`;

export enum IRankingOverView {
  CONTRACT_COUNT = 1,
  REVENUE_SUM = 2,
  BROKERAGE_FEE_SUM = 3,
}

export const TAB_DIVISIONS = '1';
export const TAB_USERS = '2';

export const DIVISON_TAB_1 = '1';
export const DIVISON_TAB_2 = '2';

export const RANKING_TAB_1 = '1';
export const RANKING_TAB_2 = '2';

export const PHASE_ZERO = {
  id: 10, // hard code
  title: '新規',
};

export const FILTER_ME = -1;
export const FILTER_ALL = -2;

export const SOCKET_CHANNELS = {
  RANKING_CONTRACT_USER: 'suns_company_database_contract-user',
  RANKING_REVENUE_USER: 'suns_company_database_revenue-user',
  RANKING_BROKERAGE_FEE_USER: 'suns_company_database_brokerage-user',
  RANKING_CONTRACT_DIVISION: 'suns_company_database_contract-division',
  RANKING_REVENUE_DIVISION: 'suns_company_database_revenue-division',
  RANKING_BROKERAGE_FEE_DIVISION: 'suns_company_database_brokerage-division',
};

export const SOCKET_ROOMS = {
  RANKING_CONTRACT_USER: (companyId) => `${SOCKET_CHANNELS.RANKING_CONTRACT_USER}-${companyId}`,
  RANKING_REVENUE_USER: (companyId) => `${SOCKET_CHANNELS.RANKING_REVENUE_USER}-${companyId}`,
  RANKING_BROKERAGE_FEE_USER: (companyId) => `${SOCKET_CHANNELS.RANKING_BROKERAGE_FEE_USER}-${companyId}`,
  RANKING_CONTRACT_DIVISION: (companyId) => `${SOCKET_CHANNELS.RANKING_CONTRACT_DIVISION}-${companyId}`,
  RANKING_REVENUE_DIVISION: (companyId) => `${SOCKET_CHANNELS.RANKING_REVENUE_DIVISION}-${companyId}`,
  RANKING_BROKERAGE_FEE_DIVISION: (companyId) => `${SOCKET_CHANNELS.RANKING_BROKERAGE_FEE_DIVISION}-${companyId}`,
};

export function getRoomName(companyId: string | number, tab, type) {
  if (!companyId) return '';
  if (tab === RANKING_TAB_1) {
    switch (+type) {
      case IRankingOverView.CONTRACT_COUNT:
        return SOCKET_ROOMS.RANKING_CONTRACT_DIVISION(companyId);
      case IRankingOverView.REVENUE_SUM:
        return SOCKET_ROOMS.RANKING_REVENUE_DIVISION(companyId);
      case IRankingOverView.BROKERAGE_FEE_SUM:
        return SOCKET_ROOMS.RANKING_BROKERAGE_FEE_DIVISION(companyId);
      default:
        break;
    }
  }
  if (tab === RANKING_TAB_2) {
    switch (+type) {
      case IRankingOverView.CONTRACT_COUNT:
        return SOCKET_ROOMS.RANKING_CONTRACT_USER(companyId);
      case IRankingOverView.REVENUE_SUM:
        return SOCKET_ROOMS.RANKING_REVENUE_USER(companyId);
      case IRankingOverView.BROKERAGE_FEE_SUM:
        return SOCKET_ROOMS.RANKING_BROKERAGE_FEE_USER(companyId);
      default:
        break;
    }
  }
  return '';
}

export const OTHER_PURPOSE_ID = 9;
export const OTHER_ADVERTISING_ID = 12;
