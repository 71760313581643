import React, { useState, useEffect, useMemo, Children, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Badge, Menu } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useToggleSideNav from 'hooks/useToggleSideNav';
import logo from 'assets/images/pokelis-logo.svg';
import { useTranslation } from 'react-i18next';
import GenerateSvgIcon from 'common/icon';
import { getRole } from 'common/function';
import { USER_ROLE } from 'common/constant';
import { useNotifications } from 'hooks/useProfile';
import { AuthContext } from 'context/auth';
import { SideNavIcon } from 'types';
import { SubMenu, MenuItem, MenuWrapper, TextHelper, SideNav as SideNavStyled, Spin } from 'components/Theme';
import useCompanyDetail from 'hooks/useCompanyDetail';
interface IMenu {
  key: string;
  text: string;
  url: string;
  icon?: any;
  notify?: number;
  children?: IMenu[];
}

export default function SideNav() {
  const { t } = useTranslation();
  const { theme: initTheme } = useContext(AuthContext);

  const collapsed = useToggleSideNav();
  const history = useHistory();
  const role = getRole();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('1');
  const [tabActived, setTabActived] = useState('1');

  const { data: dataNotification } = useNotifications();
  const { me } = useContext(AuthContext);
  const notification = dataNotification?.data;
  const requestNoti = notification?.request_close_project ?? 0;

  const { data, isLoading, isFetching } = useCompanyDetail({ id: me?.company });
  const logoCompany = data?.data?.logo_image;
  const logoText = data?.data?.name;

  const menus: IMenu[] = useMemo(() => {
    const routes = [
      {
        order: 1,
        key: '5',
        text: t('common.scheduleManagement'),
        url: '/schedules',
        icon: <GenerateSvgIcon type={SideNavIcon.SCHEDULES} tabActived={tabActived} />,
        // notify: calendarNoti,
      },
      {
        order: 2,
        key: '8',
        text: t('common.ranking'),
        url: '/ranking',
        icon: <GenerateSvgIcon type={SideNavIcon.RANKING} tabActived={tabActived} />,
      },
      {
        order: 3,
        key: '6',
        text: t('common.matterManagement'),
        url: '/projects',
        icon: <GenerateSvgIcon type={SideNavIcon.PROJECTS} tabActived={tabActived} />,
        children: [
          {
            key: '6.1',
            text: t('common.matterManagement'),
            url: '/projects',
          },
          {
            key: '6.2',
            text: t('common.pendingProjectClose'),
            url: '/request-close-project',
            notify: requestNoti,
          },
        ],
      },
      {
        order: 4,
        key: '4',
        text: t('common.customerManagement'),
        url: '/customers',
        icon: <GenerateSvgIcon type={SideNavIcon.CUSTOMERS} tabActived={tabActived} />,
      },
      {
        order: 5,
        key: '1',
        text: t('common.propertyManagement'),
        url: '/real-estate',
        icon: <GenerateSvgIcon type={SideNavIcon.REAL_ESTATE} tabActived={tabActived} />,
      },
      {
        order: 6,
        key: '3',
        text: t('common.employeeManagement'),
        url: '/users',
        icon: <GenerateSvgIcon type={SideNavIcon.USERS} tabActived={tabActived} />,
      },
      {
        order: 7,
        key: '2',
        text: t('common.departmentManagement'),
        url: '/departments',
        icon: <GenerateSvgIcon type={SideNavIcon.DEPARTMENTS} tabActived={tabActived} />,
      },
      {
        order: 14,
        key: '14',
        text: t('common.masterSetting'),
        url: '/master-setting',
        icon: <GenerateSvgIcon type={SideNavIcon.SETTING} tabActived={tabActived} />,
        children: [
          {
            key: '14.1',
            text: t('common.settingAuthentication'),
            url: '/master-setting?mode=authentication-setting',
          },
          {
            key: '14.2',
            text: t('common.colorPickSetting'),
            url: '/master-setting?mode=theme-setting',
          },
        ],
      },
    ];
    const yourRoutes =
      role === USER_ROLE.ADMIN_CMS_COMPANY
        ? [
            {
              order: 7.5,
              key: '13',
              text: t('common.companyInformation'),
              url: '/company',
              icon: <GenerateSvgIcon type={SideNavIcon.COMPANY} tabActived={tabActived} />,
            },
          ]
        : [];
    return [...yourRoutes, ...routes].sort((a, b) => a.order - b.order);
  }, [t, tabActived, requestNoti, role]);

  const handleClickSideNav = (e: any) => {
    switch (e.key) {
      case '1':
        history.push('/real-estate');
        break;
      case '2':
        history.push('/departments');
        break;
      case '3':
        history.push('/users');
        break;
      case '4':
        history.push('/customers');
        break;
      case '5':
        history.push('/schedules');
        break;
      case '6':
        history.push('/projects');
        break;
      case '7':
        history.push('/request-close-project');
        break;
      case '8':
        history.push('/ranking');
        break;
      case '9':
        history.push('/notifications');
        break;
      case '10':
        history.push('/inquiries');
        break;
      case '11':
        history.push('/files');
        break;
      case '12':
        history.push('/master');
        break;
      case '13':
        history.push('/company');
        break;
      case '14':
        history.push('/master-setting');
        break;
      default:
        history.push('/');
        break;
    }
  };

  const handleClickSubMenu = (e: any) => {
    switch (e.key) {
      case '1.1':
        history.push('/real-estate');
        break;
      case '1.2':
        history.push('/real-estate-waiting');
        break;
      case '4.1':
        history.push('/customers');
        break;
      case '4.2':
        history.push('/customers-waiting');
        break;
      case '14.1':
        history.push('/master-setting?mode=authentication-setting');
        break;
      case '14.2':
        history.push('/master-setting?mode=theme-setting');
        break;
    }
  };

  useEffect(() => {
    const { pathname } = location;
    menus.forEach((route) => {
      if (location.pathname.startsWith('/department')) {
        setSelectedKey('2');
        setTabActived('2');
      } else if (pathname.startsWith('/manager')) {
        setSelectedKey('3');
        setTabActived('3');
      } else if (pathname.startsWith('/staff')) {
        setSelectedKey('3');
        setTabActived('3');
      } else if (pathname.startsWith('/user')) {
        setSelectedKey('3');
        setTabActived('3');
      } else if (location.pathname.startsWith('/project')) {
        setSelectedKey('6.1');
        setTabActived('6');
      } else if (location.pathname.startsWith('/request-close-project')) {
        setSelectedKey('6.2');
        setTabActived('6');
      } else if (location.pathname.startsWith('/customer')) {
        setSelectedKey('4');
        setTabActived('4');
      } else if (location.pathname.startsWith('/company')) {
        setSelectedKey('13');
        setTabActived('13');
      } else if (location.pathname.startsWith('/master-setting') && location.search.includes('theme-setting')) {
        setSelectedKey('14.2');
        setTabActived('14.2');
      } else if (
        location.pathname.startsWith('/master-setting') &&
        location.search.includes('authentication-setting')
      ) {
        setSelectedKey('14.1');
        setTabActived('14.1');
      } else if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
        setTabActived(route.key);
      }

      if (Array.isArray(route?.children)) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || '###')) {
            setSelectedKey(childRoute.key);
            setTabActived(route.key);
          }
        });
      }
      if (location.pathname.startsWith('/profile') || location.pathname.startsWith('/admin')) {
        setSelectedKey('');
        setTabActived('');
      }
    });
  }, [location.pathname, location.search]);

  return (
    <SideNavStyled
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      style={{ width: collapsed ? 80 : 215, transition: 'width 0.3s' }}
    >
      <Spin spinning={isLoading || isFetching}>
        <Link className={styles.logo} to="/schedules">
          {logoCompany ? (
            <>
              <img height={50} alt="logo" src={logoCompany} />
              <TextHelper className={styles.logoText} fontSize="large">
                {logoText}
              </TextHelper>
            </>
          ) : (
            <>
              <img className="mr-10px" height={50} alt="logo" src={logo} />
              <TextHelper className={styles.logoText} fontSize="large">
                {logoText}
              </TextHelper>
            </>
          )}
        </Link>
      </Spin>
      <MenuWrapper
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[]}
        mode="inline"
        inlineCollapsed={collapsed}
        className={styles.sideNavMenu}
      >
        {menus.map((route) => {
          if (route.children) {
            return (
              <SubMenu key={route.key} icon={route.icon} title={route.text}>
                {route.children?.map((childRoute: any) => (
                  <Menu.Item key={childRoute.key} onClick={handleClickSubMenu}>
                    <div className="flex-start">
                      <Link to={childRoute.url}>{childRoute.text}</Link>
                      {childRoute.notify ? <Badge size="small" className="ml-auto" count={childRoute.notify} /> : null}
                    </div>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }

          return (
            <MenuItem key={route.key} icon={route.icon} onClick={(e) => handleClickSideNav(e)}>
              <div className="flex-start">
                <Link to={route.url}>{route.text}</Link>
                {route.notify ? <Badge size="small" className="ml-auto" count={route.notify} /> : null}
              </div>
            </MenuItem>
          );
        })}
      </MenuWrapper>
      <p className={styles.powerBy} style={{ backgroundColor: initTheme.primary }}>
        Power by SUNS GROUP
      </p>
    </SideNavStyled>
  );
}
