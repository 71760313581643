import React from 'react';

export interface ITheme {
  textColor: string;
  hoverColor: string;

  /** daisyui */
  primary: string;
  secondary: string;
  accent: string;
  neutral: string;
  'base-100': string;
  info: string;
  success: string;
  warning: string;
  error: string;
  /** daisyui */

  previewBackgroundColor: string;

  logo_url: string;
  logo_text: string;
}

interface IValue {
  theme: ITheme;
  setTheme(ITheme): void;
  me?: {
    id: number;
    first_name: string;
    last_name: string;
    kana_first_name: string;
    kana_last_name: string;
    email: string;
    email_verified_at: string;
    position: number;
    dob: string;
    gender: number;
    phone: string;
    postcode: number;
    address: string;
    avatar: string;
    status: number;
    flag: number;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    company: number;
    division: number;
    manager: number;
    employee_code: string;
    push_notify_enable: number;
    email_notify_enable: number;
    commission_rate: number;
    position_name: string;
    division_name: string;
    company_name: string;
    role_id: number;
    username: string;
    furigana: string;
    divisions: any[];
    certificates: [];
    project_ids: any[];
  };
  refetch(): void;
}

/**
 * @description Initial
  {
    "textColor": "#fff",
    "hoverColor": "#fdaf0033",

    "primary": "#6419E6",
    "secondary": "#D926A9",
    "accent": "#1FB2A6",
    "neutral": "#191D24",
    "base-100": "#2A303C",
    "info": "#3ABFF8",
    "success": "#36D399",
    "warning": "#FBBD23",
    "error": "#F87272",
  }
 */

export const AuthContext = React.createContext<IValue>({
  me: undefined,
  theme: {
    textColor: '#fff',
    hoverColor: '#fdaf0033',

    primary: '#00a19d',
    secondary: '#7B92B2',
    accent: '#67CBA0',
    neutral: '#181A2A',
    'base-100': '#FFFFFF',
    info: '#3ABFF8',
    success: '#36D399',
    warning: '#FBBD23',
    error: '#F87272',

    previewBackgroundColor: '#FFFFFF',

    logo_url: '',
    logo_text: 'ポケリス',
  },
  setTheme: () => {},
  refetch: () => {},
});
