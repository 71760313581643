import React from 'react';
import styled from 'styled-components';
import { Timeline as TimelineAntd } from 'antd';
import { getPrimary, getPrimaryText } from 'helper/theme';

export const Timeline = styled(TimelineAntd)`
  && {
    .phase-dot:hover {
      background-color: ${getPrimary};
    }
    .phase-dot.phase-dot-active {
      color: ${getPrimaryText};
      background-color: ${getPrimary};
    }
    span.active {
      color: ${getPrimary};
    }
    .ant-timeline-item-head {
      background: transparent;
    }
    .ant-timeline-item-tail {
      ${({ theme }) => {
        return `border-color: ${getPrimary}`;
      }}
    }
  }
`;

export const TimelineItem = styled(Timeline.Item)``;
