import React from 'react';
import { Button as ButtonAntd } from 'antd';
import styled from 'styled-components';
import { getPrimary, getPrimaryHover, getPrimaryText, getTextColor } from 'helper/theme';

export const Button = styled(ButtonAntd)`
  &&&& {
    transition: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${({ theme, type = 'primary' }) => {
      switch (type) {
        case 'primary':
          return `
            background:  ${getPrimary({ theme })};
            color: ${getTextColor({ theme })};
            border-color: ${getPrimary({ theme })};

            svg,path {
              fill: ${getPrimaryText({ theme })};
            }
          `;
        case 'link':
          return `
            color: ${getPrimary({ theme })};
          `;
        case 'default':
          return `
            color: ${getPrimary({ theme })};
            border-color: ${getPrimary({ theme })};
          `;
        case 'ghost':
          return `
              color: ${getPrimary({ theme })};
              border: none;
              background: #fff;
            `;
        case 'text':
        default:
          return '';
      }
    }}

    &:focus,
    &:active,
    &:hover {
      ${({ theme, type = 'primary' }) => {
        switch (type) {
          case 'primary':
            return `
              background:  ${getPrimary({ theme })};
              color: ${getPrimaryHover({ theme })};
              border-color: ${getPrimary({ theme })};
              // opacity: 0.75;

              svg,path {
                fill: ${getPrimaryHover({ theme })};
              }
            `;
          case 'link':
            return `
              border-color: transparent;
            `;
          case 'text':
            return '';
          case 'dashed':
          default:
            return `
              color: ${getPrimary({ theme })};
              border-color: ${getPrimary({ theme })};
            `;
        }
      }}
    }
  }
`;
