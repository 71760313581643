import { getPrimary } from 'helper/theme';
import React from 'react';
import styled from 'styled-components';

type FontSize = 'small' | 'medium' | 'large' | 'xlarge';

export const WithColor = styled.div`
  color: ${getPrimary};
`;

export const TextHelper = styled.span<{ fontSize?: FontSize }>`
  color: ${getPrimary};
  font-size: ${({ fontSize }) => {
    switch (fontSize) {
      case 'large':
        return '32px';
      default:
        return '14px';
    }
  }};
`;
