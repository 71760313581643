import React from 'react';
import { getPrimary } from 'helper/theme';
import styled from 'styled-components';

export const SvgWrapper = styled.div`
  &&&& {
    color: ${getPrimary};
    svg,
    path {
      fill: ${getPrimary};
    }
  }
`;
