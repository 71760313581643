import { getNotifications, getUserProfile, updateProfile } from 'api/profile';
import { AxiosError } from 'axios';
import { handleErrorMessage } from 'helper';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

export default function useProfile() {
  const history = useHistory();
  const res = useQuery(['get_user_profile'], () => getUserProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    onSuccess: (res) => {
      if (res?.data) {
        localStorage.setItem('email', res?.data?.email);
        localStorage.setItem('role', `${res?.data?.role_id}`);
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 401) {
        localStorage.clear();
        Cookies.remove('token');
        history.push('/login');
      }
    },
  });
  return res;
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation((payload: any) => updateProfile(payload), {
    onSuccess: (res) => {
      // queryClient.invalidateQueries("get_detail_employee");
      queryClient.invalidateQueries('get_user_profile');
    },
    onError: (error: any) => {
      handleErrorMessage(error);
    },
  });
};

export function useNotifications() {
  return useQuery(['getNotifications', {}], () => getNotifications(), {
    refetchOnWindowFocus: true,
  });
}
