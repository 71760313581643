import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';

import { AuthContext } from 'context/auth';
import useProfile from 'hooks/useProfile';
import { getTextColor, hexToRgbA, isHex, isRgb } from 'helper';
import { getPrimaryHover, getPrimaryText } from 'helper/theme';

import styles from './styles.module.scss';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import { PageContent } from 'components/Theme/PageContent';
import { Spin } from 'components/Theme';

const DepartmentList = lazy(() => import('pages/Department/DepartmentList'));
const DetailDepartment = lazy(() => import('pages/Department/DetailDepartment'));
const RealEstateListApproved = lazy(() => import('pages/RealEstate/List'));
const DetailRealEstate = lazy(() => import('pages/RealEstate/Detail'));
const CustomerList = lazy(() => import('pages/Customer/CustomerList'));
const CustomerDetail = lazy(() => import('pages/Customer/CustomerDetail'));
const ManagerList = lazy(() => import('pages/Managers/ManagerList'));
const EmployeeDetail = lazy(() => import('pages/Managers/EmployeeDetail'));
const Tasks = lazy(() => import('pages/Tasks'));
const ProjectList = lazy(() => import('pages/Project/ProjectList'));
const ProjectListClose = lazy(() => import('pages/Project/ProjectListClose'));
const ProjectDetail = lazy(() => import('pages/Project/ProjectDetail'));
const DetailPhase = lazy(() => import('pages/Project/DetailPhase'));
const HistoryProject = lazy(() => import('pages/Project/HistoryProject'));
const ScheduleList = lazy(() => import('pages/Schedule/ScheduleList'));
const DetailEvent = lazy(() => import('pages/Schedule/DetailEvent'));
const Ranking = lazy(() => import('pages/Ranking'));
const Company = lazy(() => import('pages/Company/CompanyList'));
const CompanySetting = lazy(() => import('pages/Company/CompanySetting'));
const Users = lazy(() => import('pages/Users'));
const NotFound = lazy(() => import('pages/NotFound'));
const MasterSetting = lazy(() => import('pages/MasterSetting'));

export default function PageWrapper() {
  const isAuthenticated = !!Cookies.get('token');
  const { t } = useTranslation();
  const { data, refetch, isLoading, isFetching } = useProfile();

  const { theme: initTheme } = useContext(AuthContext);
  const [theme, setTheme] = useState(cloneDeep(initTheme));
  const me = data?.data;

  useEffect(() => {
    if (isAuthenticated) {
      if (me?.color_web) {
        setTheme({
          ...theme,
          ...me?.color_web,
        });
        return;
      }
      if (isHex(theme.primary)) {
        const rgbObj = hexToRgbA(theme.primary);
        const previewBackgroundColor = `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, 0.2)`;
        setTheme({
          ...theme,
          textColor: getTextColor(hexToRgbA(theme.primary)),
          hoverColor: getTextColor(hexToRgbA(theme.primary)),
          previewBackgroundColor,
        });
      } else if (isRgb(theme.primary)) {
        setTheme({
          ...theme,
          textColor: getPrimaryText({ theme }),
          hoverColor: getPrimaryHover({ theme }),
        });
      }
    }
  }, [isAuthenticated, me]);
  if (!isAuthenticated) return <Redirect to="/login" />;
  return (
    <AuthContext.Provider value={{ me, theme, setTheme, refetch }}>
      <ThemeProvider theme={theme}>
        <Spin spinning={isLoading}>
          <div className={styles.pageWrapper}>
            <SideNav />
            <div className={styles.mainWrapper}>
              <PageHeader />
              <PageContent className={styles.pageContent}>
                <Suspense fallback={null}>
                  <Switch>
                    <Route path="/" exact component={RealEstateListApproved} />
                    <Route path="/company" exact component={Company} />
                    <Route path="/company/setting" exact component={CompanySetting} />
                    <Route path="/profile" exact component={EmployeeDetail} />
                    <Route path="/real-estate" exact component={RealEstateListApproved} />
                    <Route path="/departments" exact component={DepartmentList} />
                    <Route path="/department/:id" component={DetailDepartment} />
                    <Route path="/customers" exact component={CustomerList} />
                    <Route path="/customer/:id" component={CustomerDetail} />
                    <Route path="/admin/:id" exact component={EmployeeDetail} />
                    <Route path="/real-estate/:id" component={DetailRealEstate} />
                    <Route path="/tasks" component={Tasks} />
                    <Route path="/manager/:id" exact component={EmployeeDetail} />
                    <Route path="/staff/:id" exact component={EmployeeDetail} />
                    <Route path="/projects" exact component={ProjectList} />
                    <Route path="/project/:id" exact component={ProjectDetail} />
                    <Route path="/request-close-project" exact component={ProjectListClose} />
                    <Route path="/request-close-project/:id" exact component={ProjectDetail} />
                    <Route path="/project/detail-phase/:id" exact component={DetailPhase} />
                    <Route path="/project/:id/history" exact component={HistoryProject} />
                    <Route path="/schedules" exact component={ScheduleList} />
                    <Route path="/schedules/details" exact component={DetailEvent} />
                    <Route path="/ranking" exact component={Ranking} />
                    <Route path="/users" exact component={Users} />
                    <Route path="/master-setting" exact component={MasterSetting} />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </Suspense>
              </PageContent>
            </div>
          </div>
        </Spin>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}
