import React from 'react';
import styled from 'styled-components';
import { Radio as RadioAntd } from 'antd';
import { getPrimary, getPrimaryHover } from 'helper/theme';

// why remove :hover with getPrimaryHover -> it cause black background with primary is light

export const Radio = styled(RadioAntd)`
  &&& {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${getPrimary};
    }
    .ant-radio-inner::after {
      background-color: ${getPrimary};
    }

    &:hover {
      background-color: rgb(245, 245, 245);
    }
  }
`;
