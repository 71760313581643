import configs from 'config';
import { message } from 'antd';
import i18n from 'i18next';
import dayjs, { Dayjs } from 'dayjs';
import { FORMAT_DATE_TIME_24, TYPE_SORT_CUSTOMER, USER_ROLE } from 'common/constant';
import type { RgbaColor } from 'types';

export const handleErrorMessage = (error: any) => {
  message.destroy();
  message.error(getErrorMessage(error));
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || i18n.t('common.loginFailed');
};

export function convertValuesToFormData(values: any) {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    if (Array.isArray(values[key])) {
      values[key].forEach((item) => {
        if (item || item === 0) {
          formData.append(`${key}[]`, item);
        }
      });
    } else if (values[key]) {
      formData.append(key, values[key]);
    }
  });
  return formData;
}

/**
 * react-big-calendar need to be ISO 8601 format with "T"
 * https://github.com/jquense/react-big-calendar/issues/1186
 * @param datetime
 * @returns Date
 */
export function convertCalendarDateTime(datetime: string) {
  const result = dayjs(datetime).format();
  return new Date(result);
}

export function mixTimeToDate(date: Dayjs, datetime: Dayjs) {
  const hour = datetime.hour();
  const minute = datetime.minute();
  const second = datetime.second();
  return date.hour(hour).minute(minute).second(second);
}

interface Params {
  date: string;
  start: string;
  end: string;
}

/**
 * date: selected date
 * start: event.meeting_start_time
 * end: event.meeting_end_time
 * @param params {date, start, end, repeatType}
 * @returns
 */
export function getMeetingTime({ date, start, end }: Params) {
  // ------|start|------|end|-------
  const startMeeting = dayjs(start).set('hour', 0).set('minute', 0).set('second', 0);
  const endMeeting = dayjs(end).set('hour', 0).set('minute', 0).set('second', 0);
  const diffStartEnd = startMeeting.diff(endMeeting, 'day');
  if (diffStartEnd === 0) {
    // same day
    // date selected same between start and end
    const dateMeeting = dayjs(date).set('hour', 0).set('minute', 0).set('second', 0);
    if (dateMeeting.diff(startMeeting, 'day') === 0) {
      return { start, end };
    }
    return {
      start: mixTimeToDate(dateMeeting, dayjs(start)).format(FORMAT_DATE_TIME_24),
      end: mixTimeToDate(dateMeeting, dayjs(end)).format(FORMAT_DATE_TIME_24),
    };
  }
  if (diffStartEnd > 0) {
    // start greater than end
    return { start, end };
  }
  const dateMeeting = dayjs(date).set('hour', 0).set('minute', 0).set('second', 0);
  const diffDateStart = dateMeeting.diff(startMeeting, 'day');
  if (diffDateStart === 0) {
    // start is same day with selected date
    return {
      start,
      end: dayjs(start).set('hour', 23).set('minute', 59).set('second', 59).format(FORMAT_DATE_TIME_24),
    };
  }
  const diffDateEnd = dateMeeting.diff(endMeeting, 'day');
  if (diffDateEnd === 0) {
    // end is same day with selected date
    return {
      start: dayjs(end).set('hour', 0).set('minute', 0).set('second', 0).format(FORMAT_DATE_TIME_24),
      end,
    };
  }
  return { start, end };
}

export function getDivName(divisionName?: any[], divisions?: any[]) {
  if (divisionName) return divisionName;
  return divisions?.map((item) => item.name)?.join(', ') ?? '';
}
export function getValuesSort(payload) {
  let sort: any = {
    sort_by: undefined,
    sort_type: undefined,
  };
  switch (payload) {
    case TYPE_SORT_CUSTOMER.RES_YEAR_ASC: {
      sort.sort_by = 'residence_year';
      sort.sort_type = 'asc';
      break;
    }
    case TYPE_SORT_CUSTOMER.RES_YEAR_DESC: {
      sort.sort_by = 'residence_year';
      sort.sort_type = 'desc';
      break;
    }
    case TYPE_SORT_CUSTOMER.AGE_ASC: {
      sort.sort_by = 'birthday';
      sort.sort_type = 'asc';
      break;
    }
    case TYPE_SORT_CUSTOMER.AGE_DESC: {
      sort.sort_by = 'birthday';
      sort.sort_type = 'desc';
      break;
    }
    case TYPE_SORT_CUSTOMER.REGIS_DATE_ASC: {
      sort.sort_by = 'created_at';
      sort.sort_type = 'asc';
      break;
    }
    case TYPE_SORT_CUSTOMER.REGIS_DATE_DESC: {
      sort.sort_by = 'created_at';
      sort.sort_type = 'desc';
      break;
    }
    default: {
      sort.sort_by = undefined;
      sort.sort_type = undefined;
    }
  }
  return sort;
}

export function downloadURI(uri: string, name?: string) {
  const link = document.createElement('a');
  if (name) {
    link.download = name;
  }
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link.remove();
}

export function isStaff(role: number | string) {
  return '' + role === USER_ROLE.USER_ROLE;
}

export function isManager(role: number | string) {
  return '' + role === USER_ROLE.MANAGER_ROLE;
}

export function isAdmin(role: number | string) {
  return '' + role === USER_ROLE.ADMIN_CMS_COMPANY;
}

export function rgba2hex({ r, g, b, a }: RgbaColor) {
  let hex =
    (r | (1 << 8)).toString(16).slice(1) + (g | (1 << 8)).toString(16).slice(1) + (b | (1 << 8)).toString(16).slice(1);

  // multiply before convert to HEX
  let _a = ((a * 255) | (1 << 8)).toString(16).slice(1);
  hex = '#' + hex + _a;
  return hex;
}

export const rgbToHex = (r: number, g: number, b: number): string => {
  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

export function getBrightness({ r, g, b }: RgbaColor) {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

export function getTextColor(color: RgbaColor) {
  const textColor = getBrightness(color) > 192 || color.a < 0.5 ? '#000000' : '#FFFFFF';
  return textColor;
}

export function isLight(brightness: number): boolean {
  return brightness > 128 || brightness < 0.5;
}

export function hexToRgbA(hex, rgba?: { a: number }): RgbaColor {
  if (isRgb(hex)) {
    const tmp = hex.replace('rgb(', '').replace(')', '').split(',');
    return {
      r: +tmp[0].trim(),
      g: +tmp[1].trim(),
      b: +tmp[2].trim(),
      a: rgba?.a ?? 1,
    };
  }
  if (!isHex(hex)) {
    return { r: 0, g: 0, b: 0, a: 0 };
  }
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
      a: rgba?.a ?? 1,
    };
    // return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
  }
  throw new Error('Bad Hex');
}

export function rgbStrToRgbaObj(rgb: string) {
  let [r, g, b] = rgb.replace('rgb', '').replace('(', '').replace(')', '').split(',');
  return {
    r: +r.trim(),
    g: +g.trim(),
    b: +b.trim(),
    a: 1,
  };
}

export function isHex(hex: string) {
  return hex.startsWith('#');
}

export function isRgb(rgb: string) {
  return rgb.startsWith('rgb');
}

export function formatPostalCode(code: string) {
  if (!code) return '';
  code = code.substring(0, 7);
  code = code.replace(/[^0-9０-９\-\ー]/g, '');
  return code.replace(/(\d{3})(\d{4})/, '$1-$2');
}

export function formatPhoneNumber(phone: string) {
  if (!phone) return '';
  // get 11 first digits number
  const phoneFormatted = phone.substring(0, 11);
  if (phoneFormatted.length === 10) {
    return phoneFormatted.replace(/(\d{3})(\d{4})(\d{3})/, '$1-$2-$3');
  }
  return phoneFormatted.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
}
