import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';

const FirstStepLogin = lazy(() => import('pages/Authen/Login/FirstStep'));
const SecondStepLogin = lazy(() => import('pages/Authen/Login/SecondStep'));
const SendEmailStep = lazy(() => import('pages/Authen/ForgotPassword/SendEmailStep'));
const VerifyCodeStep = lazy(() => import('pages/Authen/ForgotPassword/VerifyCodeStep'));
const ResetPassword = lazy(() => import('pages/Authen/ForgotPassword/ResetPasswordStep'));
const CustomerQR = lazy(() => import('pages/Customer/CustomerQR'));
const CustomerQRSuccess = lazy(() => import('pages/Customer/CustomerQRSuccess'));
const CustomerQRError = lazy(() => import('pages/Customer/CustomerQRError'));

export default function AppWrapper() {
  return (
    <div className="root-wrapper">
      <Switch>
        <Route path="/login" exact component={FirstStepLogin} />
        <Route path="/login-verify" exact component={SecondStepLogin} />
        <Route path="/forgot-password" exact component={SendEmailStep} />
        <Route path="/forgot-password-verify" exact component={VerifyCodeStep} />
        <Route path="/reset-password" exact component={ResetPassword} />
        <Route path="/customer-token/:token" exact component={CustomerQR} />
        <Route path="/customer-token-success" exact component={CustomerQRSuccess} />
        <Route path="/customer-token-error" exact component={CustomerQRError} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
