import React from 'react';
import styled from 'styled-components';
import { Tabs as TabsAntd } from 'antd';
import { getPrimary } from 'helper/theme';

export const Tabs = styled(TabsAntd)`
  && {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background-color: ${({ theme, type }) => {
        switch (type) {
          case 'line':
            return 'transparent';
          default:
            return getPrimary({ theme });
        }
      }};
      color: ${({ theme, type }) => {
        switch (type) {
          case 'line':
            return getPrimary({ theme }) + ' !important';
          default:
            return theme.textColor + ' !important';
        }
      }};
    }
    .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn {
      border: 2px solid ${getPrimary};
    }
    && {
      .ant-tabs-tab:nth-of-type(1) {
        border-right: unset;
      }
    }
  }
`;
