import React from 'react';
import styled from 'styled-components';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getPrimary } from 'helper/theme';

export const localizer = momentLocalizer(moment);
export const Calendar = styled(BigCalendar)`
  .custom-toolbar {
    color: ${getPrimary};
  }
  .rbc-date-cell.rbc-now > div {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border-top: 3px solid ${getPrimary};
    }
  }
`;
