const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,

  FIREBASE_VAPI_KEY: process.env.REACT_APP_FIREBASE_VAPI_KEY,
  FIREBASE_APIKEY: process.env.REACT_APP_FIREBASE_APIKEY,
  FIREBASE_AUTHDOMAIN: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  FIREBASE_DATABASEURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  FIREBASE_PROJECTID: process.env.REACT_APP_FIREBASE_PROJECTID,
  FIREBASE_STORAGEBUCKET: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  FIREBASE_MESSAGINGSENDERID: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  FIREBASE_APPID: process.env.REACT_APP_FIREBASE_APPID,
  FIREBASE_MEASUREMENTID: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
};

export default configs;
