import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { Menu, Dropdown } from 'antd';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import notiImg from 'assets/images/notification.svg';
import defaultAva from 'assets/images/default-avatar.jpg';
import { AuthContext } from 'context/auth';
import RoleDisplayWithIcon from 'components/User/RoleDisplayWithIcon';

export default function PageHeader() {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { me: userInfo } = useContext(AuthContext);

  const toggleSideNav = () => {
    queryClient.setQueryData('showSideNav', (value) => !value);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  };

  const menu = (
    <Menu style={{ minWidth: 200 }}>
      <Menu.Item key="1" onClick={() => history.push('/profile')}>
        {t('common.customerInfo2')}
      </Menu.Item>
      <Menu.Item key="3" onClick={handleLogout}>
        {t('common.logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.menuWrapper}>
        <div className={styles.menuItem}>
          {/* <img alt="noti" src={notiImg} className={styles.noti} /> */}
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.infoContainer}>
              <div>
                <span>{userInfo?.email}</span>
                <RoleDisplayWithIcon role={userInfo?.role_id || 0} />
              </div>
              &nbsp;
              <img className={styles.icon} src={userInfo?.avatar ?? defaultAva} alt="" />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
