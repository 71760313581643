import { Dayjs } from 'dayjs';

export interface PhaseItem {
  calendar_id: number;
  creator: string;
  m_phase_project_id: number;
  master_phase_projects: string;
  meeting_end_time: string;
  meeting_start_time: string;
  title: string;
}

export interface ICompany {
  id: number;
  name: string;
  province: null | string;
  district: null | string;
  street: null | string;
  address: string;
  phone: string;
  website: null | string;
  commission_rate: null | number;
  note: null | string;
  status: number;
  created_at: string;
  updated_at: null | string;
  deleted_at: null | string;
  total_users: number;
  managers: number;
  finish_projects: number;
  in_progess_projects: number;
  admins: {
    id: number;
    first_name: string;
    last_name: string;
    kana_first_name: string;
    kana_last_name: string;
    username: string;
    furigana: string;
    company: number;
  }[];
}

export interface ICustomField {
  company: string;
  company_id: number;
  created_at: string;
  deleted_at: null | string;
  id: number;
  is_required: number;
  length: number;
  master_field_id: number;
  name: string;
  note: string;
  pattern: string;
  pattern_type: number;
  type: 'text' | 'string';
  updated_at: string;
}

export enum RankingType {
  Contract = 1,
  Revenue = 2,
  Brokeage = 3,
}

export interface IParamsRanking {
  start_date?: Dayjs;
  end_date?: Dayjs;
  divisions?: number[];
  type?: RankingType;
}

export interface IDivision {
  id: number;
  name: string;
  company_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  project_count: number;
  project_in_progress_count: number;
  project_success_count: number;
  manager_count: number;
  user_count: number;
}

export interface DataType {
  key: React.Key;
  username: string;
  age: number;
  address: string;
}

export enum SideNavIcon {
  REAL_ESTATE = 1,
  DEPARTMENTS = 2,
  USERS = 3,
  CUSTOMERS = 4,
  SCHEDULES = 5,
  PROJECTS = 6,
  RANKING = 8,
  COMPANY = 13,
  SETTING = 14,
}

export interface RgbaColor {
  r: number;
  g: number;
  b: number;
  a: number;
}
