import React, { Suspense, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from './wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs from 'config';
import dayjs from 'dayjs';
import { ConfigProvider, message } from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import updateLocale from 'dayjs/plugin/updateLocale';

require('dayjs/locale/ja');

dayjs.extend(updateLocale);
dayjs.updateLocale('ja', {
  weekStart: 1,
});
export const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
      retry: false,
    },
  },
});

function App() {
  return (
    <ConfigProvider locale={ja_JP} autoInsertSpaceInButton={false}>
      {message.config({
        top: 0,
        duration: 2,
        maxCount: 3,
      })}
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <Suspense fallback={null}>
            <RootWrapper />
          </Suspense>
        </Router>
        {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
