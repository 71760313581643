import React from 'react';
import styled from 'styled-components';
import { Table as TableAntd } from 'antd';
import { getPrimary } from 'helper/theme';

export const Table = styled(TableAntd)`
  .ant-pagination {
    .ant-pagination-item-active {
      background-color: ${getPrimary};
    }
  }
  .ant-pagination .anticon {
    color: ${getPrimary};
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${getPrimary};
  }
  .ant-spin-dot-item {
    background-color: ${getPrimary};
  }
`;
