import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const getUserProfile = () => sendGet('/api/v1/profile');
export const updateProfile = (params: any) => sendPost('/api/v1/profile', params);
export const updateAvatar = (params: any, userId: number | string) =>
  sendPost(`/api/v1/users/update_avatar/${userId}`, params);
export const deleteCertificate = (params: any) => sendDelete(`/api/v1/certificates/${params.certificate_id}`, params);
export const addCertificate = (params: any, userId: number | string) =>
  sendPost(`/api/v1/${userId}/certificates`, params);
export const updateCertificate = (params: any, id: any) => sendPost(`/api/v1/certificates/${id}`, params);
export const updatePassWord = (params: any) => sendPost(`/api/v1/password`, params);

export const getNotifications = () => sendGet('/api/v1/total_notifies');
