import React from 'react';
import { getCompany } from 'api/company';
import { useQuery } from 'react-query';

function useCompanyDetail({ id }) {
  return useQuery(
    ['useCompanyDetail', { id }],
    async () => {
      return getCompany({ id });
    },
    {
      enabled: !!id,
    }
  );
}

export default useCompanyDetail;
