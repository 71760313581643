import type { ITheme } from 'context/auth';
import { getTextColor as getColorFromRgb, hexToRgbA, isHex, isRgb, rgbStrToRgbaObj } from 'helper';

export const getPrimary = ({ theme }: { theme: ITheme }) => theme.primary;
export const getPrimaryHover = ({ theme }: { theme: ITheme }) => theme.hoverColor;
export const getPrimaryText = ({ theme }: { theme: ITheme }) => theme.textColor;
export const getPrimaryBackground = ({ theme }: { theme: ITheme }) => {
  const rgba = hexToRgbA(theme.primary, { a: 0.2 });
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
};

export const getBase = ({ theme }: { theme: ITheme }) => theme['base-100'];
export const getTextColor = ({ theme }: { theme: ITheme }) => theme.textColor;

export function getPrimaryObj(color: string) {
  if (!color) return {};
  if (isRgb(color)) {
    const rgbObj = rgbStrToRgbaObj(color);
    const textColor = getColorFromRgb(rgbObj);
    return {
      primary: color,
      textColor: textColor,
      hoverColor: textColor,
      'base-100': `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, 0.01)`,
      previewBackgroundColor: `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, 0.2)`,
      rgbPrimary: `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})`,
    };
  }
  if (isHex(color)) {
    const textColor = getPrimaryText({ theme: { primary: color } } as any);
    return {
      primary: color,
      textColor,
      hoverColor: textColor,
      'base-100': hexToRgbA(color, { a: 0.01 }),
      previewBackgroundColor: hexToRgbA(color, { a: 0.2 }),
      rgbPrimary: hexToRgbA(color, { a: 1 }),
    };
  }
  return {};
}
