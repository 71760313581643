import React from 'react';
import { Switch as SwitchAntd } from 'antd';
import styled from 'styled-components';
import { getPrimary } from 'helper/theme';

export const Switch = styled(SwitchAntd)`
  &&&& {
    ${({ checked, theme }) => {
      if (checked) {
        return `background: ${getPrimary({ theme })}`;
      }
    }}
  }
`;
