import React from 'react';
import styled from 'styled-components';
import { Input as InputAntd } from 'antd';
import { getPrimary } from 'helper/theme';

const { TextArea: TextAreaAntd } = InputAntd;

const TextArea = styled(TextAreaAntd)`
  && {
    &:focus {
      border-bottom-color: ${getPrimary};
    }
  }
`;

export { TextArea };
