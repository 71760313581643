import { message } from 'antd';
import Cookies from 'js-cookie';
import i18n from 'i18next';
import dayjs from 'dayjs';
import common, { weekDays, listDay, FILTER_ALL, FILTER_ME, USER_ROLE } from '../common/constant';
import QueryString from 'qs';
export function logout() {
  Cookies.remove('dataLogin');
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  Cookies.remove('unconfirmInterview');
}

export function regexPassword() {
  return new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/); // at least one character, at least one number, at least 8 character
}

export function regexNumber() {
  return new RegExp(/^[0-9]+$/);
}

export function maxLength50() {
  return new RegExp(/^.{1,50}$/);
}

export function getRole() {
  return localStorage.getItem('role');
}

export function getRoleName(roleId: number) {
  switch (`${roleId}`) {
    case USER_ROLE.ADMIN_CMS_SYSTEM:
      return 'common.roleSystem';
    case USER_ROLE.ADMIN_CMS_COMPANY:
      return 'common.roleAdmin';
    case USER_ROLE.MANAGER_ROLE:
      return 'common.roleManager';
    case USER_ROLE.USER_ROLE:
      return 'common.roleStaff';
    default:
      return 'common.roleStaff';
  }
}

export function formatPostalCode(value: any) {
  return `${value.substr(0, 3)}-${value.substr(3, value.length + 1)}`;
}

export function formatNumber(value: number) {
  return `${value.toLocaleString('ja-JP')}`;
}

export function formatMoney(value: any) {
  return value % 10000 === 0 && value / 10000 > 0
    ? `${(value / 10000).toLocaleString('ja-JP')}万円`
    : `${value.toLocaleString('ja-JP')}円`;
}

export function formatMoneyWithCurrency(value: any) {
  const isValid = value % 10000 === 0 && value / 10000 > 0;
  const obj = {
    value: isValid ? `${(value / 10000).toLocaleString('ja-JP')}` : `${value.toLocaleString('ja-JP')}`,
    currency: isValid ? '万円' : '円',
  };
  return obj;
}

export function formatPhone(value: any) {
  if (!value) return '';
  const phone = value?.replace(/[^\d]/g, '');
  return `${phone?.substr(0, 3)}-${phone?.substr(3, 4)}-${phone?.substr(7)}`;
}

export function fullwidthToHalfwidthNumber(value: any) {
  let result = value.replace(/[\uff01-\uff5e]/g, function (ch: any) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  return result;
}

export function validateImageType(file: File, isMultiUpload) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
  const isValidSize = file.size / 1024 / 1024 < 10;
  if (!isJpgOrPng || !isValidSize) {
    isMultiUpload
      ? message.error(i18n.t('validate.invalidImagesUpload'))
      : message.error(i18n.t('validate.invalidImageUpload'));
    return;
  }
  return true;
}

export function validateFileUpload(file: File) {
  const name = file.name.toLowerCase();
  const type =
    name.includes('.png') ||
    name.includes('.csv') ||
    name.includes('.pdf') ||
    name.includes('.docx') ||
    name.includes('.jpeg') ||
    name.includes('.jpg');
  const isValidSize = file.size / 1024 / 1024 < 10;
  if (!type || !isValidSize) {
    message.error(i18n.t('validate.invalidFileUpload'));
    return;
  }
  return true;
}

export function validateFileUploadByType(file: File, typeFile) {
  const name = file.name.split('.').pop();
  const isValidSize = file.size / 1024 / 1024 < 5;
  if (!typeFile.includes(name) || !isValidSize) {
    message.error(i18n.t('validate.invalidFileUploadCSV'));
    return;
  }
  return true;
}

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function validateRatio(rule: any, value: any, callback: any) {
  if (value) {
    if (!common.REGEX_ONLY_NUMBER_AND_DOT.test(value) || value > 100 || value < 0) {
      return callback(i18n.t('validate.type_less_than_100'));
    } else callback();
  }
  callback();
}

export function validateArea(rule: any, value: any, callback: any) {
  if (value) {
    if (!common.REGEX_ONLY_NUMBER_AND_DOT.test(value) || value > 999999 || value < 0) {
      return callback(i18n.t('validate.inputInvalid'));
    } else callback();
  }
  callback();
}

export function validateKana(rule: any, value: any, callback: any) {
  if (value && (value.length > 15 || !common.REGEX_KANA.test(value))) {
    callback(i18n.t('validate.maxLength_15_kana'));
  } else callback();
}

export function getKeywordSearch(path: any, currentKey: any, nextKey: any) {
  let keyword = path.slice(
    path.indexOf(currentKey) + currentKey.length,
    nextKey === '' ? path.length : path.indexOf(nextKey)
  );
  return keyword !== '' ? keyword : undefined;
}

export function formatDateCalendar(date: any) {
  return `${weekDays[dayjs(date).get('day')]}、${dayjs(date).format('YYYY年MM月DD日 HH:mm')}`;
}

export function formatDate(date: any) {
  return `${dayjs(date).format('YYYY年MM月DD日')}（${listDay[dayjs(date).get('day')]}）`;
}

export function formatDate2(date: any) {
  return `${dayjs(date).format('MM月DD日 HH:mm')}`;
}

export function formatDate3(date: any) {
  return `${dayjs(date).format('YYYY年MM月DD日')} (${listDay[dayjs(date).get('day')]})`;
}

export function formatDay(date: any) {
  return `${dayjs(date).format(`M月D日 ${weekDays[dayjs(date).get('day')]}`)}`;
}

export const getStartAndEndMonthCalendar = (date?: any) => {
  let dayStart = dayjs(date).startOf('month').day() || 7;
  let dayEnd = dayjs(date).endOf('month').day() || 7;
  let startCalendar = dayjs(dayjs(date).startOf('month'))
    .subtract(dayStart - 1, 'day')
    .format('YYYY-MM-DD');
  let endCalendar = dayjs(dayjs(date).endOf('month'))
    .add(7 - dayEnd, 'day')
    .format('YYYY-MM-DD');
  return {
    startCalendar,
    endCalendar,
  };
};

/**
 * mix date and time
 * @param inputDate 'YYYY-MM-DD HH:mm:ss'
 * @param outputDate 'YYYY-MM-DD'
 * @returns outputDate + inputTime
 */
export const convertDate = (inputDate: string, outputDate: string) => {
  const oldDate = dayjs(inputDate);
  const minutes = oldDate.minute();
  const seconds = oldDate.second();
  const hour = oldDate.hour();
  return dayjs(outputDate).set('hour', hour).set('minutes', minutes).set('seconds', seconds).format();
};

function checkZero(data: any) {
  if (data.length == 1) {
    data = '0' + data;
  }
  return data;
}

export const checkSameDay = (date: any) => {
  let startDay = dayjs(date?.meeting_start_time).format('YYYY-MM-DD');
  let endDay = dayjs(date?.meeting_end_time).format('YYYY-MM-DD');
  return startDay == endDay;
};

export function getParamsCalendar(search: string) {
  const queryParams = QueryString.parse(search?.split('?')?.[1]);
  const getKeyMonth =
    queryParams?.month && dayjs(`${queryParams.month}`).isValid() ? `${queryParams.month}` : undefined;
  const getKeyDay = queryParams?.day && dayjs(`${queryParams.day}`).isValid() ? `${queryParams.day}` : undefined;
  let getKeyAction: any = queryParams?.action ? +`${queryParams.action}` : undefined;
  const getKeyType: any =
    !queryParams?.type || +`${queryParams?.type}` === FILTER_ALL ? undefined : +`${queryParams.type}`;
  const getKeyDivision =
    !`${queryParams?.division}`.trim() ||
    +`${queryParams?.division}` === FILTER_ALL ||
    isNaN(+`${queryParams.division}`)
      ? undefined
      : +`${queryParams.division}`;
  const getKeyUser =
    !`${queryParams?.user}`.trim() || +`${queryParams?.user}` === FILTER_ALL || isNaN(+`${queryParams.user}`)
      ? undefined
      : +`${queryParams.user}`;
  const getKeyTitle = queryParams?.title ? `${queryParams.title}` : undefined;
  return {
    getKeyMonth,
    getKeyDay,
    getKeyAction,
    getKeyType,
    getKeyDivision,
    getKeyUser,
    getKeyTitle,
  };
}

export function getFullAddress(row: any) {
  if (!row) return '';
  const province = row?.province ? `${row.province} ` : '';
  const district = row?.district ? `${row.district} ` : '';
  const address = row?.address ?? '';
  return province + district + address;
}

export function getFullname(firstName: string | null, lastName: string | null) {
  firstName = firstName ?? '';
  lastName = lastName ?? '';
  return `${lastName}${firstName ? ` ${firstName}` : ''}`;
}
