import React from 'react';
import { Steps as StepsAntd } from 'antd';
import styled from 'styled-components';
import { getPrimary } from 'helper/theme';

export const Steps = styled(StepsAntd)`
  && {
    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: ${getPrimary};
      background-color: ${getPrimary};
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      background-color: ${getPrimary};
      color: white;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: ${getPrimary};
    }
    .ant-steps-item-process .ant-steps-item-icon {
      border-color: ${getPrimary};
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
      color: ${getPrimary};
      font-size: 16px;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background: ${getPrimary};
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
      position: absolute;
      top: -26px;
      left: 50%;
      width: 100%;
      text-align: center;
      font-weight: bold;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
`;
